import React from "react";
import "../styles/footer.css";
const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="bg-azul text-center text-lg-start p-1 fixed-bottom">
      <div className="container text-center text-md-start mt-5">
        <div className="row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
            <a
              href="https://grupoalianzacolombia.com/"
              target="_blank"
              rel="noreferrer"
              className="centrar"
            >
              <div className="animation-wrap">
                <div className="gac">
                  <div className="adelante gac2"></div>
                  <div className="atras">
                    <img src="/images/Logo-white.png" alt="img_logo_GAC" />
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mx-auto">
            <p className="text-uppercase fw-bold mb-4">Legales</p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/sagrilaft    "
                className="text-reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>SAGRILAFT Y PTEE</strong>
              </a>
            </p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/document/Politica-de-tratamiento-de-datos-Personales.pdf"
                target="_blank"
                className="text-reset"
                rel="noopener noreferrer"
              >
                Política de tratamiento de datos personales
              </a>
            </p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/document/Politica-De-Privacidad-1.pdf"
                target="_blank"
                className="text-reset"
                rel="noopener noreferrer"
              >
                Aviso de privacidad
              </a>
            </p>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto">
            <p className="text-uppercase fw-bold mb-4"></p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/politica-de-ley-de-retracto/"
                className="text-reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                Política ley de retracto
              </a>
            </p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/politica-de-proteccion-de-menores"
                className="text-reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                Política de protección a menores
              </a>
            </p>
            <p>
              <a
                href="https://grupoalianzacolombia.com/bio-seguridad/"
                className="text-reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bio-seguridad
              </a>
            </p>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-md-0">
            <p className="text-uppercase fw-bold mb-4">Contacto</p>
            <p>Bogotá, Colombia</p>
            <p>
              <a
                className="text-white"
                href="mailto:sac@grupoalianzacolombia.com"
                rel="noopener noreferrer"
              >
                sac@grupoalianzacolombia.com
              </a>
            </p>
            <p>
              <a
                className="text-white"
                href="tel:+576012205214"
                rel="noopener noreferrer"
              >
                (601)2205214
              </a>
            </p>
            <p>
              <a
                className="text-white"
                href="tel:+576015520100"
                rel="noopener noreferrer"
              >
                (601)5520100
              </a>
            </p>
          </div>
        </div>
        <div className="text-center p-4">
          <span>
            RNT No 127027. Grupo Alianza Colombia SAS advierte al usuario que en
            desarrollo de lo dispuesto en la Ley 679/01 y 1336/09 la explotación
            y el abuso sexual de niños, niñas y adolescentes en el país son
            sancionadas penal y administrativamente, conforme a las leyes
            vigentes. Oficina Principal Bogotá, Calle 93B # 17-25 Oficina
            309-310-311 Edificio Centro Empresarial PH.
          </span>
          <p>
            <a
              className="text-reset fw-bold"
              href="https://grupoalianzacolombia.com/"
              rel="noopener noreferrer"
            >
              © {year} | Todos los derechos reservados. Grupo Alianza Colombia
            </a>
          </p>
        </div>
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
