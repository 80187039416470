import React, { useState } from 'react';
import { RequestPayu } from '../../service/getPayU';
import Load from '../Load';

const InternationalPayments = ({form_pay, setform_pay, setGoSteps}) => {
  const [StateLoad, setStateLoad] = useState(false)
  const select_pay = () => { 
    setGoSteps(2)
  }

  const handleClick = async () => {
    setStateLoad(true)
    try {
      const response = await RequestPayu(form_pay);
      console.log('Respuesta del servicio:', response);
      setform_pay({
        ...form_pay,
        url_payu: response.data
      })
      setGoSteps(2)
    } catch (error) {
      console.error('Error al llamar al servicio:', error);
    setStateLoad(true)
    }finally{ setStateLoad(false)}
  };
  return (
    <main className='container'>
      {
        StateLoad ?
          <Load /> :
        <section className='row centrar'>
          <button onClick={ () => handleClick()} className='btn-international'>
            <img src='https://www.svgrepo.com/show/408253/world-map.svg' alt='pago internacional priority' width={30}/>
            Pagar desde el exterior
          </button>
          <button onClick={ () => select_pay()} className='btn-colombia'>
            Pagar desde Colombia
            <img src='https://www.svgrepo.com/show/405455/flag-for-flag-colombia.svg' alt='pago colombia priority' width={30}/>
          </button>
        </section>
      }
    </main>
  )
}

export default InternationalPayments